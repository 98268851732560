import { ColumnDef, ColumnFiltersState, PaginationState, Row, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import {
  getTrackerServicesReportTableSettings,
  saveTrackerServicesReportTableSettings,
  trackerServicesReportTableDefaults,
} from "../../../services/localStorage";
import { fetchTrackerServicesReport } from "../../../services/trackerServiceReport";
import MoreIcon from "../../../svgs/MoreIcon";
import addOrUpdate from "../../../util/addOrUpdate";
import { isSuper } from "../../../util/checkRole";
import copyToClipboard from "../../../util/copyToClipboard";
import { formatUTCDateToLocal } from "../../../util/dateUtils";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import { printFixedTemp, printTemp, printTempUnit } from "../../../util/formatUnits";
import { kegOrTracker } from "../../../util/kegOrTracker";
import { getTableFilters } from "../../../util/urlParamFilters";
import Badge from "../../Badge";
import { PrimaryBtn } from "../../Buttons";
import CreateTrackerServiceLogModal from "../../CreateTrackerServiceLogModal";
import { PrimaryIconBtn } from "../../IconButtons";
import NewTable from "../../NewTable";
import { DesktopDiv, MobileDiv } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Panel } from "../../Panel";
import Tooltip from "../../Tooltip";
import { MenuButton, MenuList } from "../../Tooltip/styles";
import TrackerServiceLogsModal from "../../TrackerServiceLogsModal";
import { MoreIconContainer, MoreIconSize } from "../../UsersScreen/styles";

type TrackerServiceLogs = {
  serviceStatus: string;
  trackerId: string;
  lastServicedBy: string;
  dateProvisioned: Date | null;
  lastServiceDate: Date | null;
  nextServiceDue: Date | null;
  serviceFrequencyMonths: number;
  roomTemperature: number | null;
  actions: string;
  notes: string;
  certificateUrl: string;
  organisationId: string | null;
  organisationName: string;
};

const TrackerServicesReport: FC<any> = ({ onSuccess }) => {
  const { color } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "lastServiceDate", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [createModalOpen, setCreateModalOpen] = useState<any>(false);
  const [viewAllModalOpen, setViewAllModalOpen] = useState<any>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getTrackerServicesReportTableSettings() ? getTrackerServicesReportTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const getTrackerServices = () => {
    setDataLoading(true);
    fetchTrackerServicesReport(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    fetchTrackerServicesReport(source, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Tracker Services Report.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = [
      "Service Status",
      "Tracker ID",
      "Date Provisioned",
      "Last Service Date",
      "Next Service Due",
      "Service Frequency (months)",
      "Last Serviced By",
      `Room Temperature (${printTempUnit()})`,
      "Actions",
      "Notes",
      "Organisation",
      "Certificate URL",
    ];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.serviceStatus,
          row.trackerId,
          row.dateProvisioned != null ? formatUTCDateToLocal(row.dateProvisioned, "FULL") : "",
          row.lastServiceDate != null ? formatUTCDateToLocal(row.lastServiceDate, "FULL") : "",
          row.nextServiceDue != null ? formatUTCDateToLocal(row.nextServiceDue, "FULL") : "",
          row.serviceFrequencyMonths,
          row.lastServicedBy,
          printFixedTemp(row.roomTemperature, 1),
          row.actions,
          row.certificateUrl,
          row.notes,
        ];

        return rows;
      }, []),
    ];
  };

  const updateTable = (newData: any) => {
    setData((prev: any) => addOrUpdate(prev, newData, "id"));
    setSorting((prev) => [...prev]); // This will trigger sorting recalculation
    if (onSuccess) onSuccess();
  };

  const columns = React.useMemo<ColumnDef<TrackerServiceLogs>[]>(
    () => [
      {
        id: "actions-menu",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }: { row: Row<TrackerServiceLogs> }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  <MenuButton
                    onClick={() => {
                      setSelectedRow(row.original);
                      setViewAllModalOpen(true);
                    }}
                  >
                    View All Service Logs
                  </MenuButton>
                  {isSuper() && (
                    <MenuButton
                      onClick={() => {
                        setSelectedRow(row.original);
                        setCreateModalOpen(true);
                      }}
                    >
                      Create Service Log
                    </MenuButton>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Service Status",
        accessorKey: "serviceStatus",
        cell: (props: any) => (
          <Badge
            title={props.getValue()}
            background={props.getValue() === "Overdue" ? color.danger_dark[2] : props.getValue() === "Okay" ? color.success_dark[2] : color.other[2]}
          >
            {props.getValue()}
          </Badge>
        ),
        meta: {
          filterType: "select",
          selectKey: "value",
          selectOptions: [
            { value: "Okay", label: "Okay", colour: color.success_dark[2] },
            { value: "Overdue", label: "Overdue", colour: color.danger_dark[2] },
            { value: "Never Serviced", label: "Never Serviced", colour: color.other[2] },
            { value: "Disabled", label: "Disabled", colour: color.other[2] },
          ],
        },
        filterFn: undefined,
        size: 190,
      },
      {
        header: "Tracker ID",
        accessorKey: "trackerId",
        cell: (props: any) => {
          const url = kegOrTracker("kegs", "trackers");
          return (
            <Link title={props.getValue()} to={`/${url}/${props.getValue()}`}>
              {props.getValue()}
            </Link>
          );
        },
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        header: "Date Provisioned",
        accessorKey: "dateProvisioned",
        cell: (props: any) => (props.getValue() ? formatUTCDateToLocal(props.getValue()) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Last Service Date",
        accessorKey: "lastServiceDate",
        cell: (props: any) => (props.getValue() ? formatUTCDateToLocal(props.getValue()) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Next Service Due",
        accessorKey: "nextServiceDue",
        cell: (props: any) => (props.getValue() ? formatUTCDateToLocal(props.getValue()) : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Service Frequency",
        accessorKey: "serviceFrequencyMonths",
        cell: (props: any) => (props.getValue() ? (props.getValue() === 1 ? props.getValue() + " month" : props.getValue() + " months") : ""),
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Last Serviced By",
        accessorKey: "lastServicedBy",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Room Temperature",
        accessorKey: "roomTemperature",
        cell: (props: any) => printTemp(props.getValue(), 1),
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Actions",
        accessorKey: "actions",
        cell: (props: any) => props.getValue(),
        // <Tooltip
        //   content={
        //     props.getValue() != null
        //       ? props
        //           .getValue()
        //           .split(",")
        //           .map((action: string) => <p>{action}</p>)
        //       : ""
        //   }
        // >
        //   <div
        //     style={{
        //       cursor: "not-allowed",
        //       userSelect: "none",
        //     }}
        //   >
        //     <span style={{ textDecoration: "underline", fontWeight: 500, cursor: "pointer" }}>{props.getValue()}</span>
        //   </div>
        // </Tooltip>
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Notes",
        accessorKey: "notes",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      ...(isSuper()
        ? [
            {
              header: "Organisation",
              accessorKey: "organisationName",
              meta: {
                filterType: "autoComplete",
                selectKey: "label",
                loadOptionsKey: "organisations",
              },
              filterFn: undefined,
              size: 200,
            },
          ]
        : []),
      {
        header: "Certificate URL",
        accessorKey: "certificateUrl",
        cell: (props: any) =>
          props.getValue() !== "" && (
            <a href={props.getValue()} target="_blank" rel="noreferrer">
              View Certificate
            </a>
          ),
        size: 180,
      },
    ],
    [color]
  );

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/admin-reports", title: "Admin Reports" }]} currRoute="Tracker Services Report" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <Panel>
            <NewTable
              data={data}
              count={count}
              dataErr={dataErr}
              dataLoading={dataLoading}
              columns={columns}
              sorting={sorting}
              setSorting={setSorting}
              columnFilters={columnFilters}
              columnFiltersDebounced={columnFiltersDebounced}
              setColumnFilters={setColumnFilters}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
              fetchData={getTrackerServices}
              fetchCsv={fetchCsv}
              defaultTableSettings={trackerServicesReportTableDefaults}
              getTableSettings={getTrackerServicesReportTableSettings}
              saveTableSettings={saveTrackerServicesReportTableSettings}
              dataTypeName="Service Logs"
              emptyDataMsg="Create a new service log above to get started"
              TableButtons={
                <div>
                  {isSuper() && (
                    <>
                      <DesktopDiv>
                        <PrimaryBtn
                          onClick={() => {
                            setSelectedRow(null);
                            setCreateModalOpen(true);
                          }}
                        >
                          Create
                        </PrimaryBtn>
                      </DesktopDiv>
                      <MobileDiv>
                        <Tooltip content="Create">
                          <PrimaryIconBtn
                            onClick={() => {
                              setSelectedRow(null);
                              setCreateModalOpen(true);
                            }}
                          >
                            <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                              <HiPlus />
                            </IconContext.Provider>
                          </PrimaryIconBtn>
                        </Tooltip>
                      </MobileDiv>
                    </>
                  )}
                </div>
              }
            />
          </Panel>
        </div>
      </PageContainer>
      {createModalOpen && (
        <CreateTrackerServiceLogModal
          trackerId={selectedRow?.trackerId}
          onSuccess={updateTable}
          modalOpen={createModalOpen}
          setModalOpen={setCreateModalOpen}
        />
      )}
      {viewAllModalOpen && (
        <TrackerServiceLogsModal trackerId={selectedRow?.trackerId} onSuccess={updateTable} modalOpen={viewAllModalOpen} setModalOpen={setViewAllModalOpen} />
      )}
    </>
  );
};

export default TrackerServicesReport;
