// src/utils/dateUtils.ts
import { DateTime } from "luxon";

/**
 * Parses an ISO 8601 UTC datetime string and returns a Luxon DateTime object in the user's local timezone.
 *
 * @param isoString - The ISO 8601 datetime string in UTC.
 * @returns A Luxon DateTime object in the local timezone or null if invalid.
 */
export const parseUTCToLocal = (isoString: string): DateTime | null => {
  const dateTime = DateTime.fromISO(isoString, { zone: "utc" });
  return dateTime.isValid ? dateTime.toLocal() : null;
};

/**
 * Formats a Luxon DateTime object to a human-readable date string.
 *
 * @param dateTime - The Luxon DateTime object to format.
 * @param formatType - The format type ('MED', 'FULL', 'SHORT').
 * @returns The formatted date string or 'Invalid Date' if the input is invalid.
 */
export const formatDate = (dateTime: DateTime | null, formatType: "MED" | "FULL" | "SHORT" = "MED"): string => {
  if (!dateTime || !dateTime.isValid) return "Invalid Date";

  switch (formatType) {
    case "FULL":
      return dateTime.toLocaleString(DateTime.DATETIME_FULL);
    case "SHORT":
      return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
    case "MED":
    default:
      return dateTime.toLocaleString(DateTime.DATETIME_MED);
  }
};

/**
 * Combines parsing a UTC ISO string and formatting it to a local date string.
 *
 * @param isoString - The ISO 8601 datetime string in UTC.
 * @param formatType - The format type ('MED', 'FULL', 'SHORT').
 * @returns The formatted local date string or 'Invalid Date' if parsing fails.
 */
export const formatUTCDateToLocal = (isoString: string, formatType: "MED" | "FULL" | "SHORT" = "MED"): string => {
  const localDateTime = parseUTCToLocal(isoString);
  return formatDate(localDateTime, formatType);
};
