import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { BsChatRightTextFill, BsTags, BsThermometerHalf } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GiBackwardTime } from "react-icons/gi";
import { HiArrowCircleDown, HiArrowCircleRight, HiArrowCircleUp, HiExclamationCircle } from "react-icons/hi";
import { HiHashtag, HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoIosBeer } from "react-icons/io";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { hideAll } from "tippy.js";
import { deletePhoto } from "../../services/photo";
import CameraIcon from "../../svgs/CameraIcon";
import IoIosBeerEmpty from "../../svgs/IoIosBeerEmpty";
import TapIcon from "../../svgs/TapIcon";
import { isAdmin } from "../../util/checkRole";
import { printLength, printTemp } from "../../util/formatUnits";
import getNetworkIcon from "../../util/getNetworkIcon";
import getSensorIcon from "../../util/getSensorIcon";
import { googleMapsAddressLink, googleMapsLink } from "../../util/googleMapsLink";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import { DangerAlert } from "../Alerts";
import BatteryIcon from "../BatteryIcon";
import BeerBatchInfoModal from "../BeerBatchInfoModal";
import Bold from "../Bold";
import DeleteModal from "../DeleteModal";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import PhotoModal from "../PhotoModal";
import ReactIcon from "../ReactIcon";
import SignalIcon from "../SignalIcon";
import StateContext from "../StateContext";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import UploadPhotoModal from "../UploadPhotoModal";
import {
  BoxElement,
  BoxesContainer,
  CameraIconContainer,
  ContentContainer,
  Footer,
  Header,
  Icon,
  IconContainer,
  Section,
  UpdateImageButton,
  UpdateImageButtonContainer,
  Value,
  ValueContainer,
} from "./styles";
import { isBinaryLoop } from "../../util/checkDomain";

const SensorMeta: FC<any> = ({ data, dataLoading, fetchData }) => {
  const { color, color_50, short_datetime, long_datetime, short_date } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const photoMenuRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [beerBatchModalOpen, setBeerBatchModalOpen] = useState<boolean>(false);
  const [inputPhoto, setInputPhoto] = useState<any>(undefined);
  const [photoModalOpen, setPhotoModalOpen] = useState<boolean>(false);
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false);
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const {
    sensorType = null,
    productName = null,
    productId = null,
    sensorId = null,
    dateActivated = null,
    latestSampleDate = null,
    lastServiceDate = null,
    nextServiceDue = null,
    temp = null,
    place = null,
    placeId = null,
    placeTags = null,
    placeGroup = null,
    placeGroupColour = null,
    dateArrived = null,
    orientation = null,
    address = null,
    lat = null,
    lng = null,
    voltage = null,
    loadVoltage = null,
    notes = null,
    nickname = null,
    trackerTags = null,
    sensorPhotoUrl = null,
    serialNumber = null,
    network = null,
    logLimit = null,

    assetTypeId = null,
    assetTypeName = null,
    assetTypeColour = null,
    assetTypeIcon = null,
    assetId = null,

    locationAccuracy = null,
    locationType = null,
    latitude = null,
    longitude = null,
    lastGoodLocationDate = null,
    csq = null,
    snr = null,

    hasTemperature = null,
    hasOrientation = null,
    hasBeer = null,
    hasEngine = null,
    hasGps = null,
    hasLight = null,
    hasMovement = null,

    dateMarkedForPickup = null,
    datePickedUp = null,
    markedForPickupBy = null,
    pickedUpBy = null,
    pickupRequestTypeName = null,
    pickupRequestTypeColour = null,
    pickupRequestNotes = null,

    organisationId = null,
    organisationName = null,

    battery = null,
    batteryPercentage = null,

    // KEG
    dateFilled = null,
    placeFilled = null,
    placeIdFilled = null,
    placeTypeFilled = null,
    placeTypeColourFilled = null,
    beerName = null,
    batchId = null,
    freshness = null,
    empty = null,
    emptyDate = null,
    emptyReason = null,
    placeEmptied = null,
    placeIdEmptied = null,
    placeTypeEmptied = null,
    placeTypeColourEmptied = null,
    onTap = null,
    onTapDate = null,
    kegTypeId = null,
    kegTypeName = null,
    timesEmptied = null,
    litresConsumed = null,

    // ENGINE
    engineRunCount = null,
    engineRunMinutes = null,

    // MOVEMENT
    moveCount = null,

    // KRAKEN
    input1Label = null,
    Input1Mode = null,
    input1Unit = null,
    input2Label = null,
    Input2Mode = null,
    input2Unit = null,
    input3Label = null,
    Input3Mode = null,
    input3Unit = null,
    input4Label = null,
    Input4Mode = null,
    input4Unit = null,
    input5Label = null,
    Input5Mode = null,
    input5Unit = null,
    input6Label = null,
    Input6Mode = null,
    input6Unit = null,
    input7Label = null,
    Input7Mode = null,
    input7Unit = null,
    superMeta = null,
  } = data ? data : {};

  return (
    <>
      <Section>
        {!dataLoading && dateActivated == null && (
          <DangerAlert style={{ marginBottom: "10px", width: "calc(100% - 6px)" }}>
            <Bold>Tracker Not Activated</Bold>
          </DangerAlert>
        )}
        {!dataLoading && nextServiceDue != null && new Date(nextServiceDue) < new Date() && (
          <DangerAlert style={{ marginBottom: "10px", width: "calc(100% - 6px)" }}>
            <Bold>
              Tracker Service due <ReactTimeago live={false} date={nextServiceDue} />
            </Bold>
          </DangerAlert>
        )}
        {sensorPhotoUrl && (
          <div style={{ position: "relative", width: "100%" }}>
            <BoxesContainer>
              <BoxElement style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ cursor: "pointer" }} onClick={() => setPhotoModalOpen(true)}>
                  <img width="150px" height="150px" style={{ borderRadius: "3px" }} src={sensorPhotoUrl} />
                </div>
              </BoxElement>
            </BoxesContainer>
            {isAdmin() && (
              <Tooltip
                onCreate={(instance) => (photoMenuRef.current = instance)}
                interactive={true}
                touch={true}
                appendTo={document.body}
                trigger="click"
                placement="bottom-start"
                maxWidth="none"
                theme="binary-no-padding"
                content={
                  <MenuList>
                    <MenuButton
                      onClick={() => {
                        inputRef.current.click();
                        hideAll();
                      }}
                    >
                      {sensorPhotoUrl ? "Change" : "Upload"}
                    </MenuButton>
                    {sensorPhotoUrl && (
                      <DangerMenuButton
                        onClick={() => {
                          setDeletePhotoModalOpen(true);
                          hideAll();
                        }}
                      >
                        Delete
                      </DangerMenuButton>
                    )}
                  </MenuList>
                }
              >
                <UpdateImageButton style={{ background: color.panel_bg[2], bottom: "10px", right: "5px" }}>
                  <UpdateImageButtonContainer>
                    <CameraIconContainer>
                      <CameraIcon />
                    </CameraIconContainer>
                  </UpdateImageButtonContainer>
                </UpdateImageButton>
              </Tooltip>
            )}
          </div>
        )}
        {(sensorId !== null || assetId !== null || assetTypeName !== null) && (
          <BoxesContainer>
            {sensorId !== null && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>{getSensorIcon(productId, productName, color)}</Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Tracker</Header>
                    <Value>{sensorId}</Value>
                    {productName !== null && <Footer>{productName}</Footer>}
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
            {(assetId !== null || assetTypeName !== null) && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <ReactIcon
                        name={assetTypeIcon !== null ? assetTypeIcon : "GiCube"}
                        defaultColour={color_50.font_bold[2]}
                        colour={assetTypeColour ? assetTypeColour : color_50.font_bold[2]}
                        title={assetTypeName}
                        size="32px"
                      />
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>{assetId !== null && assetTypeName !== null ? "Asset" : assetId !== null ? "Asset ID" : "Asset Type"}</Header>
                    <Value>{assetId !== null ? assetId : assetTypeName}</Value>
                    {assetTypeName && assetId !== null && <Footer>{assetTypeName}</Footer>}
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
          </BoxesContainer>
        )}
        {(nickname !== null || (trackerTags !== null && trackerTags.length > 0)) && (
          <BoxesContainer>
            {nickname && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                        <HiHashtag />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Name</Header>
                    <Value>{nickname}</Value>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
            {trackerTags !== null && trackerTags.length > 0 && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                        <BsTags />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>{kegsOrTrackers("Keg Tags", "Tracker Tags")}</Header>
                    <Value>
                      {trackerTags.sort(sortTags).map((tag: any) => (
                        <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />
                      ))}
                    </Value>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
          </BoxesContainer>
        )}
        {(latestSampleDate !== null || lastGoodLocationDate !== null) && (
          <BoxesContainer>
            {latestSampleDate !== null && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <GiBackwardTime />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Last Seen</Header>
                    <Value>
                      <ReactTimeago live={false} date={latestSampleDate * 1000} title={moment.unix(latestSampleDate).format(long_datetime)} />
                    </Value>
                    <Footer>{moment.unix(latestSampleDate).format(short_datetime)}</Footer>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
            {latestSampleDate !== null && lastGoodLocationDate !== null && latestSampleDate !== lastGoodLocationDate && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <FaMapMarkerAlt />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Last Located</Header>
                    <Value>
                      <ReactTimeago live={false} date={lastGoodLocationDate * 1000} title={moment.unix(lastGoodLocationDate).format(long_datetime)} />
                    </Value>
                    <Footer>{moment.unix(lastGoodLocationDate).format(short_datetime)}</Footer>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
          </BoxesContainer>
        )}
        {lastServiceDate !== null && (
          <BoxesContainer>
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <GiBackwardTime />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Last Serviced</Header>
                  <Value>
                    <ReactTimeago live={false} date={lastServiceDate * 1000} title={moment.unix(lastServiceDate).format(long_datetime)} />
                  </Value>
                  <Footer>{moment.unix(lastServiceDate).format(short_datetime)}</Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {(hasOrientation === true || hasTemperature === true) && (orientation !== null || temp !== null) && (
          <BoxesContainer>
            {temp !== null && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <BsThermometerHalf />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Temperature</Header>
                    <Value>{printTemp(temp)}</Value>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
            {orientation !== null && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        {orientation === "S" ? <HiArrowCircleRight /> : orientation === "D" ? <HiArrowCircleDown /> : <HiArrowCircleUp />}
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Orientation</Header>
                    <Value>{orientation === "S" ? "Sideways " : orientation === "D" ? "Upside Down " : "Upright "}</Value>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
          </BoxesContainer>
        )}
        {dateMarkedForPickup !== null && (
          <BoxesContainer>
            <BoxElement style={{ display: "flex", flexDirection: "column" }}>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <HiExclamationCircle />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Marked for Pickup</Header>
                  <Value>
                    <ReactTimeago live={false} date={dateMarkedForPickup * 1000} title={moment.unix(dateMarkedForPickup).format(long_datetime)} />
                  </Value>
                  <Footer>{moment.unix(dateMarkedForPickup).format(short_datetime)}</Footer>
                </ValueContainer>
              </ContentContainer>
              <ContentContainer
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "3px",
                }}
              >
                <Footer>Requested by {markedForPickupBy != null ? markedForPickupBy : "Anonymous"}</Footer>
                {pickupRequestTypeName !== null && (
                  <Footer>
                    {isBinaryLoop() ? "Contents" : "Pickup Request Type"}:{" "}
                    {pickupRequestTypeColour !== null && <ColoredDot color={pickupRequestTypeColour} margin="0 4px" />}
                    {pickupRequestTypeName}
                  </Footer>
                )}
                {pickupRequestNotes !== null && <Footer title={`Notes: ${pickupRequestNotes}`}>Notes: {pickupRequestNotes}</Footer>}
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {hasBeer === true && (
          <BoxesContainer>
            <BoxElement style={{ display: "flex", flexDirection: "column" }}>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    {onTap === true ? (
                      <TapIcon width="32" height="32" fill={color_50.font_bold[2]} />
                    ) : (
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        {empty === false ? <IoIosBeer /> : <IoIosBeerEmpty width="32px" height="32px" fill={color_50.font_bold[2]} />}
                      </IconContext.Provider>
                    )}
                  </Icon>
                </IconContainer>
                <ValueContainer style={{ justifyContent: placeId && place ? "flex-start" : "center" }}>
                  <Header>Keg Status</Header>
                  {onTap === true ? (
                    <>
                      <Value>
                        On tap{" "}
                        {beerName !== null && (
                          <>
                            with{" "}
                            {batchId !== null ? (
                              <span title={beerName} onClick={() => setBeerBatchModalOpen(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                                {beerName}
                              </span>
                            ) : (
                              beerName
                            )}
                          </>
                        )}
                      </Value>
                      {freshness !== null && <Footer>{freshness}% fresh</Footer>}
                    </>
                  ) : empty === false ? (
                    <>
                      <Value>
                        Filled{" "}
                        {beerName && (
                          <>
                            with{" "}
                            <span title={beerName} onClick={() => setBeerBatchModalOpen(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                              {beerName}
                            </span>
                          </>
                        )}
                      </Value>
                      {freshness !== null && <Footer>{freshness}% fresh</Footer>}
                    </>
                  ) : empty === true && timesEmptied > 0 ? (
                    <>
                      <Value>
                        Emptied{" "}
                        {beerName && (
                          <>
                            with{" "}
                            <span title={beerName} onClick={() => setBeerBatchModalOpen(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                              {beerName}
                            </span>
                          </>
                        )}
                      </Value>
                      {freshness !== null && <Footer>{freshness}% fresh when emptied</Footer>}
                    </>
                  ) : empty !== false && !(timesEmptied > 0) ? (
                    <Value>Never filled</Value>
                  ) : (
                    <></>
                  )}
                </ValueContainer>
              </ContentContainer>
              <ContentContainer
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "3px",
                }}
              >
                {emptyDate && (
                  <Footer>
                    Emptied <ReactTimeago live={false} date={emptyDate * 1000} title={moment.unix(emptyDate).format(long_datetime)} /> (
                    {moment.unix(emptyDate).format(short_date)}){" "}
                    {placeEmptied !== null && placeIdEmptied !== null && (
                      <>
                        at{placeTypeColourEmptied && <ColoredDot title={placeTypeEmptied} color={placeTypeColourEmptied} margin="0 4px" />}
                        <Link title={placeEmptied} to={`/places/${placeIdEmptied}`}>
                          {placeEmptied}
                        </Link>
                      </>
                    )}
                  </Footer>
                )}
                {onTap === true && onTapDate !== null && (
                  <Footer>
                    Tapped <ReactTimeago live={false} date={onTapDate * 1000} title={moment.unix(onTapDate).format(long_datetime)} /> (
                    {moment.unix(onTapDate).format(short_date)})
                  </Footer>
                )}
                {dateFilled !== null && (
                  <Footer>
                    Filled <ReactTimeago live={false} date={dateFilled * 1000} title={moment.unix(dateFilled).format(long_datetime)} /> (
                    {moment.unix(dateFilled).format(short_date)}){" "}
                    {placeFilled !== null && placeIdFilled !== null && (
                      <>
                        at{placeTypeColourFilled && <ColoredDot title={placeTypeFilled} color={placeTypeColourFilled} margin="0 4px" />}
                        <Link title={placeFilled} to={`/places/${placeIdFilled}`}>
                          {placeFilled}
                        </Link>
                      </>
                    )}
                  </Footer>
                )}
                <Footer>{kegTypeId && kegTypeName ? `${kegTypeName} keg` : "Please select a keg type for accurate consumption metrics"}</Footer>
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {((placeId !== null && place !== null) || address !== null) && (
          <BoxesContainer>
            <BoxElement style={{ display: "flex", flexDirection: "column" }}>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider
                      value={{ color: placeGroupColour ? placeGroupColour : color_50.font_bold[2], size: placeId && place ? "60px" : "28px" }}
                    >
                      {placeId !== null && place !== null ? <HiOutlineBuildingOffice2 /> : <FaMapMarkerAlt />}
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer style={{ justifyContent: "center" }}>
                  <Header>Current Location</Header>
                  {placeId !== null && place !== null ? (
                    <Value>
                      <Link title={place} to={`/places/${placeId}`}>
                        {place}
                      </Link>
                    </Value>
                  ) : address && lat !== null && lng !== null ? (
                    <Value title={address}>{googleMapsLink(lat, lng, address)}</Value>
                  ) : address ? (
                    <Value title={address}>{googleMapsAddressLink(address)}</Value>
                  ) : (
                    <></>
                  )}
                  {placeGroup !== null && (
                    <Footer>
                      {placeGroupColour !== null && <ColoredDot color={placeGroupColour} margin="0 4px" />}
                      {placeGroup}
                    </Footer>
                  )}
                </ValueContainer>
              </ContentContainer>
              {(placeId !== null || place !== null) && (
                <ContentContainer
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "3px",
                  }}
                >
                  {dateArrived && (
                    <Footer>
                      Arrived <ReactTimeago live={false} date={dateArrived * 1000} title={moment.unix(dateArrived).format(long_datetime)} /> (
                      {moment.unix(dateArrived).format(short_date)})
                    </Footer>
                  )}
                  {address !== null && lat !== null && lng !== null ? (
                    <Footer>{googleMapsLink(lat, lng, address)}</Footer>
                  ) : address !== null ? (
                    <Footer>{googleMapsAddressLink(address)}</Footer>
                  ) : (
                    <></>
                  )}
                  {placeTags !== null && placeTags.length > 0 && (
                    <Value style={{ marginTop: "3px" }}>
                      {placeTags.sort(sortTags).map((tag: any) => (
                        <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />
                      ))}
                    </Value>
                  )}
                </ContentContainer>
              )}
            </BoxElement>
          </BoxesContainer>
        )}
        {notes && (
          <BoxesContainer>
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                      <BsChatRightTextFill />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Notes</Header>
                  <Value title={notes} style={{ fontSize: "14px", lineHeight: "17px" }}>
                    {notes}
                  </Value>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {(csq != null || network != null || locationType != null || locationAccuracy != null) && (
          <BoxesContainer>
            <BoxElement style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap", margin: "0" }}>
              {batteryPercentage != null && (
                <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
                  <ValueContainer style={{ margin: 0, width: "100px", alignItems: "center", justifyContent: "start", height: "60px" }}>
                    <Header style={{ marginBottom: "6px" }}>Battery</Header>
                    <Value style={{ alignSelf: "center", textAlign: "center" }}>
                      <BatteryIcon batteryPercentage={batteryPercentage} />
                    </Value>
                  </ValueContainer>
                </ContentContainer>
              )}
              {csq != null && (
                <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
                  <ValueContainer style={{ margin: 0, width: "100px", alignItems: "center", justifyContent: "start", height: "60px" }}>
                    <Header>Signal</Header>
                    <Value style={{ alignSelf: "center", textAlign: "center" }}>
                      <SignalIcon csq={csq} />
                    </Value>
                  </ValueContainer>
                </ContentContainer>
              )}
              {network != null && (
                <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
                  <ValueContainer style={{ margin: 0, width: "100px", alignItems: "center", justifyContent: "start", height: "60px" }}>
                    <Header style={{ marginBottom: "6px" }}>Network</Header>
                    <Value style={{ alignSelf: "center", textAlign: "center" }}>
                      <span style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "32px" }}>
                        {getNetworkIcon(network, color, theme)}
                      </span>
                    </Value>
                  </ValueContainer>
                </ContentContainer>
              )}
              {locationType != null && (
                <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
                  <ValueContainer style={{ margin: 0, width: "100px", alignItems: "center", justifyContent: "start", height: "60px" }}>
                    <Header style={{ marginBottom: "6px" }}>Source</Header>
                    <Value style={{ alignSelf: "center", textAlign: "center" }}>
                      <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{locationType}</span>
                    </Value>
                  </ValueContainer>
                </ContentContainer>
              )}
              {locationAccuracy != null && (
                <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
                  <ValueContainer style={{ margin: 0, width: "100px", alignItems: "center", justifyContent: "start", height: "60px" }}>
                    <Header style={{ marginBottom: "6px" }}>Accuracy</Header>
                    <Value style={{ alignSelf: "center", textAlign: "center" }}>
                      <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{printLength(locationAccuracy)}</span>
                    </Value>
                  </ValueContainer>
                </ContentContainer>
              )}
            </BoxElement>
          </BoxesContainer>
        )}
      </Section>
      {beerBatchModalOpen && <BeerBatchInfoModal batchId={batchId} modalOpen={beerBatchModalOpen} setModalOpen={setBeerBatchModalOpen} />}
      {photoModalOpen && (
        <PhotoModal modalOpen={photoModalOpen} setModalOpen={setPhotoModalOpen} name={kegOrTracker("Keg Photo", "Tracker Photo")} src={sensorPhotoUrl} />
      )}
      {uploadPhotoModalOpen && (
        <UploadPhotoModal
          modalOpen={uploadPhotoModalOpen}
          setModalOpen={setUploadPhotoModalOpen}
          photo={inputPhoto}
          trackerId={sensorId}
          organisationId={undefined}
          placeId={undefined}
          userId={undefined}
          onSuccess={fetchData}
        />
      )}
      {deletePhotoModalOpen && (
        <DeleteModal
          title={kegOrTracker("Delete Keg Photo", "Delete Tracker Photo")}
          body={<span>Are you sure you want to delete this photo?</span>}
          successMsg={kegOrTracker("Keg Photo Deleted", "Tracker Photo Deleted")}
          modalOpen={deletePhotoModalOpen}
          setModalOpen={setDeletePhotoModalOpen}
          deleteService={deletePhoto}
          serviceParams={[sensorId, undefined, undefined, undefined]}
          onDelete={fetchData}
        />
      )}
    </>
  );
};

export default SensorMeta;
